﻿@import "../theme/variables.scss";


@mixin bp-xs {
    @media (min-width: $breakpoint-xs) {
        @content;
    }
}

@mixin bp-sm {
    @media (min-width: $breakpoint-sm) {
        @content;
    }
}

@mixin bp-md {
    @media (min-width: $breakpoint-md) {
        @content;
    }
}

@mixin bp-lg {
    @media (min-width: $breakpoint-lg) {
        @content;
    }
}
