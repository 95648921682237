@import "../../../../../../improve-lib/theme/variables.scss";

.processIcon:before {
    content: var(--processIcon);
    font-family: "Segoe MDL2 Assets";
    font-size: 14px;
    color: $color-fluentPrimary;
}

.processIcon {
    line-height: 1;
}

.processIcon:hover {
    background-color: rgb(230, 230, 230);
}
