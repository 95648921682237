@import "../../../improve-lib/theme/theme.scss";

.user-form-wrapper {
    max-width: 1000px;
}
.user-form-container {
    max-width: 1000px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: repeat(auto);
    gap: $grid-gap-row $grid-gap-column;
    margin: 10px 0 3px 0;
}