@import "../../../../improve-lib/theme/variables.scss";

.process-info-separator {
    height: inherit;
}

.process-info-panel {
    flex: 1;
    padding: 5px 0;
    overflow: auto;
}
