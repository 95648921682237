.file-upload-div {
    display: flex;
    align-items: flex-end;
}

.file-upload-space {
    /*margin-right: 3px;*/
    width: 3px;
}

.file-upload-input {
    display: none;
}