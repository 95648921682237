@import "../../improve-lib/theme/variables.scss";
@import "../../improve-lib/theme/mixins.scss";

.default-error-page-wrapper {
    background-color: $color-fluentWhite;
    display: flex;
    height: 100vh;
    padding-top: 55px;
    @include bp-sm {
        padding-top: 0;
    }
}

.default-error-page-image-container {
    background: linear-gradient(180deg, rgba(0,50,90,1) 6%, rgba(176,196,222,1) 21%);
    width: 40%;
    overflow: hidden; /* Avoid scrollbar in Firefox */
    display:none;
    @include bp-sm {
        display: block;
    }
}

.default-error-page-image {
    object-fit: cover;
    overflow: hidden;
    opacity: 0.6;
    height: 100vh;
    width: 100%
}

.default-error-page-status {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    padding: 12px;
    font-size: x-large;
    width: inherit;
}

.default-error-page-return {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px;
    font-size: larger;
    width: inherit;
}

.default-error-page-status-wrapper {
    width: 100%;

    @include bp-sm {
        width: 60%;
    }
}

.default-error-page-copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: inherit;
    padding-bottom: 5px;
}
