@import "../../improve-lib/theme/variables.scss";

.section-children {
    padding-left: 6px;
    padding-right: 6px;
}

.section-header {
    display: flex;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 5px;
    margin-top: 5px;
}

.section-main ~ .section-main > .section-spacer {
    width: 100%;
    height: 1px;
    background-color: $color-fluentNeutralLight;
    margin-top: 10px;
}

.child-responsive-behavior{
    overflow-y: auto;
}