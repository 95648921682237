.multi-language-txtfield-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.multi-language-txtfield {
    flex: 1;
}

.single-language-txtfield {
    width: 100%;
}