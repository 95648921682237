@import "../../../../improve-lib/theme/variables.scss";

.title-container-main {
    background-color: $color-backgroundWhite;
}

.header-title-container {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: flex-end;
    padding-left: 10px;
    padding-top: 5px;
    padding: 5px 10px 0px 10px;
}

.title-title-container {
    align-self: center;
    margin-right: auto;
    max-width: 100%;
    word-wrap: break-word;
}

.content-title-container {
    padding-top: 5px;
}
