.multi-language-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.multi-language-txt-wrapper {
    flex: 1;
    margin-right: 10px;
}

.single-language-txt-wrapper {
    width: 100%;
}
