.pdf-fu-container {
    display: flex;
}

.pdf-fu-cb-container {
    margin-top: auto;
    margin-left: 20px;
    margin-bottom: 5px;
}


.pdf-fu-eye-container {
    margin-top: auto;
    margin-left: 7px;
}
