.edit-multi-lang-list-entry {
    display: flex;
}

.edit-multi-lang-list-txtfield {
    flex: 1;
}

.edit-multi-lang-list-newentry {
    display: flex;
    margin-top: 15px;
}
