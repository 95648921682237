%documenttag-shared {
    display: flex;
    border-style: solid; 
    border-radius: 20px 8px 8px 20px; 
}

.documenttag-div-normal {
    @extend %documenttag-shared;
    border-width: 3px;
    padding: 1px 2px 1px 10px;
    font-size: 12px;
}

.documenttag-div-small {
    @extend %documenttag-shared;
    border-width: 2px;
    padding: 1px 2px 1px 5px;
    font-size: 10px;
}
