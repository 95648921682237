.multi-language-desc-main-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.multi-language-desc-wrapper {
    flex: 1;
}

.multi-language-desc-btn-wrapper {
    padding-left: 10px;
}

.single-language-desc-wrapper {
    width: 100%;
}
