@import "../../../../improve-lib/theme/variables.scss";


.process-header-breadcrumb {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    .process-toggle {
        display:grid;
        padding-left: 9px;
    }
}
.process-header-separator::after, .process-header-separator::before {
    background-color: $color-fluentPrimary;
}