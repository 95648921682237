.nav-separator {
    border-bottom: 1px solid gray;
    margin: 1rem 0 2rem 0;
    display: block;
}

.nav-grid-item {
    place-content:center;
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    position: relative;
    margin-left: 12px;

    h5 {
        margin: 0;
    }

    .nav-item-grid {
        display: grid;
        grid-template-columns: repeat(4, max-content);
        gap: 10px;

        div {
            font-size: 0.8rem;
        }

        i {
            margin: 0 7px 0 0;
        }
    }
}


