.inlineflex .ms-ChoiceField {
    display: inline-block;
}

.ms-ChoiceField{
    width: 80px;
}

.ms-ChoiceField-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Positioning {
    width: 80px;
    font-size: 12px;
    text-align: center;
    padding-right: 10px;
    cursor: pointer;
}

.RolesDetailsPermissionMatrix {
    width: 700px;
}

.PositioningLabelApplicationArea{
    width: auto;
    display:block;
    font-size: 12px;
    text-align:left;
}

.divSpacer{
    width:25%;
    height: auto;
    display:inline-block;
}

.divMatrix{
    width:75%;
    display:inline-block;
}

.divApplicationAreas {
    width: 25%;
    display: inline-block;
    word-break: break-word;
}

.divTitles {
    padding-bottom: 5px;
    font-size: 14px;
}