.command-bar-container {
    > :last-child:has(.dot-nav) {
        margin-left: auto;
    }
}

.is-loading {
    &,button[type=button]{
    cursor: default !important;

    }
    span{
        i{
    visibility: hidden;
        }
    }
}

.spinner-container {
    display: block;
    position: relative;
    width: max-content;

    .spinner {
        position: absolute;
        top: 14px;
        left: 8px;
        z-index: 9;
    }
}
