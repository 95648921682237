.TextClamped {
    display: -webkit-box;
    -webkit-line-clamp: var(--numberOfLines);
    -webkit-box-orient: vertical;
    overflow: hidden;
    position: relative;
}

.TextExpand {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0.3;
}

.TextClamped:hover + .TextExpand,
.TextExpand:hover {
    opacity: 1;
}
.DropDownText {
    content: "\a";
    white-space: pre-line;
}
