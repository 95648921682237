@import "../../../../improve-lib/theme/variables.scss";

.viewer-main-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - ($layout-headerHeight + 85px));
}

.viewer-bpmn-container {
    position: relative;
    flex: 3;
    height: 100%;
}

.viewer-zoom-controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    bottom: 80px;
    z-index: 1;
}
