@import "../../../../improve-lib/theme/variables.scss";

.modeler-main-container {
    display: flex;
    flex-direction: row;
    height: calc(100vh - ($layout-headerHeight + 85px));
}

.modeler-bpmn-container {
    position: relative;
    flex: 3;
    height: 100%;
}

.modeler-zoom-controls {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    bottom: 80px;
    z-index: 1;
}
// classes get applied from bpmn
.djs-palette-entries {
    width: 48px;
}

.djs-palette.two-column.open {
    width: 48px;
}
