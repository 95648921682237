.iconbutton-small {
    height: 17px;
    width: 17px;
}

.iconbutton-small > span > i {
    font-size: 12px;
}

.iconbutton-large {
    height: 30px;
    width: 30px;
}

.iconbutton-large > span > i {
    font-size: 19px;
}

.iconbutton-inherit {
    height: 100%;
}
