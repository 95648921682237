#table-filter-searchbox-parent {
    position: relative;
    padding: 5px 8px 0;
}

#table-filter-searchbox-parent > div::before {
    content: var(--countEntries);
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    margin-right: 32px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}
