.step-div-main {
    padding: 4px 0;
}

.step-header-div-main {
    display: flex;
    justify-content: space-between;
    align-self: center;
    padding-right: 4px;
    border-radius: 3px;
    height: 30px;
}

.step-header-icon-and-title-div-main {
    display: flex;
    align-self: center;
    padding-left: 3px;
}

.step-header-title-div {
    padding-left: 5px;
}

.step-header-control-and-action-div-main{
    display:flex;
    gap: 10px;
    font-size: 13px;
    margin: auto 0px;
}