@import '../../improve-lib/theme/index.scss';

.childwithlabel-label {
    padding-right: 20px;
    font-weight: 500;
    color: $color-fluentThemeDarker;
}


.childwithlabel-label + div {    
    border-radius: 2px;
}

.multiline {
    padding: 6px;
    border: 1px solid lightgrey;
}
