@import "./variables.scss";

@font-face {
    font-family: 'Open Sans';
    src: url(../../public/font/OpenSans-VariableFont.woff2) format('woff2');
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: 'Open Sans';
    line-height: 1.15;
    background-color: $color-backgroundNotWhite;
}

body {
    margin: 0;
    font-size: 15px;
    line-height: 1.5;
    text-align: left;
}


h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

b,
strong {
    font-weight: bolder;
}

table {
    border-collapse: collapse;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
}


input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer;
}

textarea {
    resize: vertical;
}
