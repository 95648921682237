.roles-details-users-search-wrapper {
    display: flex;
    margin-bottom: 5px;
    width:500px
}

.roles-details-users-width {
    width: 550px;
}

.roles-details-users-search-button {
    margin-left: 20px;
    margin-top: auto;
    min-width: max-content;
}
