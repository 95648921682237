@import "../theme/variables.scss";

.error-message {
    display: block;
    margin-top: 25vh;
    background: $color-backgroundNotWhite;
}

.error-container {
    display: grid;
    place-content: center;

    i {
        place-self: center;
        margin-bottom: 3rem;
    }

    .error-body {
        border-left: 3px solid $color-errorRed;
        padding: 0 1rem;
        margin: 1rem 0 0 1rem;
        min-width: 300px;
        max-width: 32vw;

        pre {
            white-space: break-spaces;
            max-height: 6rem;
            overflow-y: auto;
        }
    }

    .error-title {
        //color: $color-fluentPrimary;
        padding: 5px 15px 0;
        text-transform: uppercase;
    }

    .error-text {
        padding: 3px 15px;
        min-width: 300px;
        max-width: 32vw;

        .hl {
            display: inline-block;
            color: $color-fluentPrimary;
        }
    }

    .error-link {
        color: $color-fluentPrimary;
        text-decoration: underline;
        padding: 3px 15px;
        font-size: 18px;

        &:hover {
            color: $color-fluentThemeTertiary;
            cursor: pointer;
        }
    }

    .error-seperator {
        border-bottom: 1px solid $color-fluentPrimary;
        margin: 3px 0;
    }
}
