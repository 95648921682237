@import "../../improve-lib/theme/variables.scss";

%item-shared {
    border-top: 1px solid $color-borderSilver;
    margin-bottom: 2px;
    display: flex;
    padding: 3px;
    color: $color-fontColor
}

.list-item {
    @extend %item-shared;
    cursor: pointer;
}

.list-item:hover {
    background-color: $color-fluentNeutralLighter;
    color: $color-fontColorHover
}

.list-item-selected {
    @extend %item-shared;
    background-color: $color-fluentNeutralLight;
}
