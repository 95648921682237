@import "../../improve-lib/theme/variables.scss";

.popup-size-tiny {
    width: 40%;
    max-width: 600px;
    max-height: 85%;

    .popup-scrollable-content {
        max-height: calc(100vh - 18%); /* https://github.com/microsoft/fluentui/issues/23388 */
    }
}

.popup-size-small {
    width: 80%;
    max-width: 800px;
    max-height: 85%;

    .popup-scrollable-content {
        max-height: calc(100vh - 18%); /* https://github.com/microsoft/fluentui/issues/23388 */
    }
}

.popup-size-medium {
    width: 80%;
    max-width: 1200px;
    max-height: 85%;

    .popup-scrollable-content {
        max-height: calc(100vh - 18%); /* https://github.com/microsoft/fluentui/issues/23388 */
    }
}

.popup-size-big {
    width: 90%;
    max-width: 1400px;
    height: 85%;

    .popup-scrollable-content {
        max-height: calc(100vh - 18%); /* https://github.com/microsoft/fluentui/issues/23388 */
    }
}

.popup-drag {
    cursor: move;
    height: 10px;
    background-color: $color-fluentThemePrimary;
}

.popup-wrapper {
    padding: 8px;
}
.popup-header{
    margin-top: 7px;
    padding-left: 8px;
}