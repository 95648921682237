@import "../theme/variables.scss";
@import "../theme/mixins.scss";

.top-bar-header-wrapper {
    padding: 0 10px;
    height: $layout-headerHeight;
    align-items: center;
    position: fixed;
    width: 100%;
    z-index: 999;
    background-color: $color-fluentPrimary;
    top: 0;
}


.top-bar-error {
    padding: 7px 0;
    height: 100%;
    margin-right: auto;
}

.top-bar-header-wrapper-transparent {
    @include bp-sm {
        background-color: transparent;

        .top-bar-header-toggle-controls {
            color: $color-fluentPrimary;
        }
    }
}

.top-bar-header-logo {
    padding-top: 5px;
    height: 70%;
    margin-right: auto;
}

.top-bar-header-burger {
    display: none;
    cursor: pointer;
}

.top-bar-header-burger-controls {
    display: block;

    @include bp-md {
        display: none;
    }
}

.top-bar-header-toggle-controls {
    display: grid;
    grid-auto-flow: column;
}

.top-bar-logout {
    display: none;

    @include bp-sm {
        display: block;
    }
}

.top-bar-logout-mobile {
    display: block;

    @include bp-sm {
        display: none;
    }
}

.top-bar-header-toggle-controls {
    color: $color-fluentWhite;
}

.navbar {
    width: max-content;
    min-width: 63px;
}

.nav-container {
    display: block;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;

    .checkbox {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .hamburger-lines {
        display: block;
        height: 21px;
        width: 27px;
        position: absolute;
        top: 19px;
        left: 27px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hamburger-lines .line {
        display: block;
        height: 3px;
        width: 100%;
        border-radius: 10px;
        background: $color-backgroundWhite;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.1s ease-in-out;
    }

    .hamburger-lines .line2 {
        transition: transform 0.1s ease-in-out;
    }

    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.1s ease-in-out;
    }
}

.burger-open {
    .menu-items {
        transform: translateX(0);
    }

    .hamburger-lines .line1 {
        transform: rotate(45deg);
    }

    .hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .hamburger-lines .line3 {
        transform: rotate(-45deg);
    }
}
