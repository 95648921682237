@import "../../improve-lib/theme/variables.scss";

.invisible-save-msg {
    visibility: hidden;
}

.visible-save-msg {
    visibility: visible;
    opacity: 1;
}

.fade-out-save-msg {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 2s;
    transition-timing-function: linear;
    transition-delay: 3s;
}

.icon-wrapper-save-msg {
    padding-top: 10px;
}

.save-wrapper-save-msg {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    background-color: $color-successGreen;
}

.command-button-action-context {
    display: inline-block;
}

.command-bar-action-context {
    display: inline-block;
}