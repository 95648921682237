@import "../../improve-lib/theme/variables.scss";

.created-edited-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 11px;
    color: $color-fontColor;
    padding: 0 10px;
}
