﻿// colors
$color-successGreen: #DFF6DD;
$color-readonlyGray: #F1F1F1;
$color-mandatoryRed: #FBCFD0;
$color-backgroundWhite: white;
$color-backgroundNotWhite: #FAF9F8;
$color-borderSilver: #c0c0c0;
$color-fontColor: #615f5d;
$color-fontColorLighter: #908E8C;
$color-fontColorHover: #333231;
$color-fontColorLighterHover: #615f5d;
$color-errorRed: rgb(168, 0, 0);
$color-warningYellow: #ffb900;

// fluent-colors
$color-fluentThemePrimary: #02457b;
$color-fluentThemeTertiary: #4881af;
$color-fluentThemeDarker: #012645;
$color-fluentNeutralLighter: #F3F2F1;
$color-fluentNeutralLight: #EDEbE9;
$color-fluentPrimary: #02457B;
$color-fluentWhite: #FFFFFF;

//layout
$layout-headerHeight: 45px;
$layout-menuWidth: 250px;

//grid
$grid-gap-column: 10px;
$grid-gap-row: 10px;

//breakpoints
$breakpoint-xs: 767px;
$breakpoint-sm: 1023px;
$breakpoint-md: 1322px;
$breakpoint-lg: 1700px;

$breakpoints:("xs" : $breakpoint-xs, "sm": $breakpoint-sm ,"md": $breakpoint-md ,"lg": $breakpoint-lg);