@import "../../../../improve-lib/theme/theme.scss";

.document-search-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 7px 7px 0;

    .sort-btn {
        display: grid;
        grid-template-columns: 1fr 27px;
        align-items: end;
        justify-content: end;
    }

    @include bp-sm {
        grid-template-columns: repeat(2, 1fr);

        .sort-btn {
            grid-template-columns: calc(100% - 40% - 27px) 27px;
            align-items: end;
            justify-content: end;
        }
    }

    .counting-label {
        justify-self: end;
        margin: 10px 0
    }
}
