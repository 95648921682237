@import "../theme/theme.scss";

.main-layout-container-wrapper {
    padding-top: $layout-headerHeight;
    display: grid;
    grid-template-columns: 1fr;

    @include bp-md {
        grid-template-columns: max-content 1fr;
    }
}

.main-layout-menu-wrapper {
    display: none;
    width: $layout-menuWidth;

    @include bp-md {
        display: block;
    }

    .nav-grid-container {
        @include bp-md {
            display: block;
            height: 100vh;
            position: fixed;
            width: 100%;
            overflow-y: auto;
            background-color: $color-fluentNeutralLighter;

            @include bp-md {
                width: $layout-menuWidth;
            }
        }
    }
}

.main-layout-menu-wrapper-open {
    width: 100%;
    z-index: 1;
    display: block;
    overflow-y: auto;
    z-index: 999;
    height: 100vh;
    position: fixed;
    width: 100%;
    overflow-y: auto;
    background-color: $color-fluentNeutralLighter;
    overflow-x: hidden;

    .nav-grid-container {
        @include bp-md {
            width: $layout-menuWidth;
        }
    }

    @include bp-md {
        display: block;
        position: relative;
        width: $layout-menuWidth;
        z-index: 1;
    }
}
