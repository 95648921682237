@import "../../improve-lib/theme/variables.scss";

.list-item-div {
    width: 100%;
}

.list-item-div-outline:focus {
    padding-left: 2px;
    padding-right: 2px;
    outline-style: solid;
    outline-width: thin;
    width: 100%;
}

.list-item-div-icon {
    margin-left: 3px;
    margin-right: 10px;
    align-self: center;
    font-size: 20px;
    width: auto;
}

.list-item-div-secondrow {
    width: 100%;
    display: flex;
    font-size: 12px;
    color: $color-fontColorLighter;
}

.list-item-div-inner:not(:last-child) {
    border-right: 1px solid $color-borderSilver;
    padding-right: 5px;
    margin-right: 5px
}

.list-status-wrapper {
    flex-wrap: wrap;
    display: inline-block;
    padding-left: 10px;
}
