﻿@import "./variables.scss";
@import "./mixins.scss";

section {
    display: grid;
    grid-template-columns: 1fr;

    @include bp-xs {
        grid-template-columns: repeat(12, 1fr);
    }
}

article {
    padding: 0 8px 8px;
}

.article-form-element {
    position: relative;
    padding: 0 0 8px;
    word-break: break-all;
}

.article-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: repeat(auto);
    gap: $grid-gap-row $grid-gap-column;
    margin: 10px 0 3px 0;

    &, .article-multilanguage {
        padding: 0 8px 8px;
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    }
}

.popup-wrapper {
    .article-content {
        &, .article-multilanguage {
            padding: 0 0 8px;
        }
    }
}


.content-container {
    padding: 10px 10px 0;
    grid-column: 1/13;
    max-width: 100vw;
    min-width: 0;
}

.main-layout-main-content-wrapper {
    padding: 5px;
}

.split-view-6-6 {
    .content-container {
        @include bp-xs {
            &:first-of-type {
                padding: 10px 5px 0 10px;
            }

            &:last-of-type {
                padding: 10px 10px 0 5px;
            }
        }

        &:last-child {
            @include bp-xs {
                grid-column: 7/13;
            }
        }

        &:first-child {
            @include bp-xs {
                grid-column: 1/7;
            }
        }
    }
}

.split-view-4-8 {
    .content-container {
        @include bp-xs {
            &:first-of-type {
                padding: 10px 5px 0 10px;
            }

            &:last-of-type {
                padding: 10px 10px 0 5px;
            }
        }

        &:last-child {
            @include bp-xs {
                grid-column: 5/13;
            }

            @include bp-sm {
                grid-column: 5/13;
            }

            @include bp-lg {
                grid-column: 4/13;
            }
        }

        &:first-child {
            @include bp-xs {
                grid-column: 1/5;
            }

            @include bp-sm {
                grid-column: 1/5;
            }

            @include bp-lg {
                grid-column: 1/4;
            }
        }
    }
}
