.documentstatus-div {
    display: flex;
    border-style: solid;
    border-radius: 10px;
    border-color: rgba(255,0,0,1);
    border-width: 2px;
    padding: 0px 5px 0px 5px;
    font-size: 10px;
    font-weight: bold;
}
