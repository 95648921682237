.callout-icon {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
}

.callout-root {
    display: flex;
    justify-content: center;
}

.callout-text {
    display: inline-block;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
}